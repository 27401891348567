



























































































































































import {Component, Vue} from 'vue-property-decorator'

@Component({})
export default class FitnessPlanInfo extends Vue {
  private percentage = 0
}
